* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.logo {
  background-image: url("logo_white.ec157b4f.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: 45px;
  max-height: 100%;
}

::-webkit-scrollbar {
  position: "absolute";
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#00eaff, #3800ef) 91% 100% / 100% 150%;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  cursor: grab;
  background-position: 10% 0;
}

::-webkit-scrollbar-thumb:active {
  cursor: grabbing;
}

html {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background-color: #000;
  font-family: Poppins, sans-serif;
  position: relative;
  overflow-x: hidden;
}

body, a {
  color: #eaeaea;
}

a {
  cursor: pointer;
  text-decoration: none;
  transition: color, font-size .3s ease-out;
}

a:hover {
  color: #bbb;
}

section {
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: calc(100vh - 100px);
  padding: 50px;
  display: flex;
}

.heading {
  margin: 20px 0;
  font-size: 4rem;
  font-weight: 500;
  line-height: 4rem;
}

.subheading {
  color: #d5d5d5;
  margin-left: 2px;
  font-size: 1.5rem;
  font-weight: 400;
}

.btn {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  display: flex;
}

.btn.btn--icon {
  justify-content: start;
}

.btn-text {
  color: #000;
  font-weight: 600;
}

.btn-icon {
  --side: 35px;
  height: var(--side);
  width: var(--side);
  background-repeat: no-repeat;
  margin: 10px 15px;
}

section {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  height: 100%;
  padding-top: 4vh;
  padding-left: 10vw;
  padding-right: 10vw;
  display: flex;
}

.page-holder {
  padding: 5vw 10vw;
}

.paragraph-holder {
  padding-bottom: 10px;
}

.paragraph-holder > ul {
  margin: 20px;
}

.paragraph-section-label {
  padding-right: 4px;
}

@media (width <= 1200px) {
  section {
    text-align: center;
    flex-direction: column-reverse;
    justify-content: space-around;
    gap: 0;
    padding: 2rem 1.5rem;
  }

  .heading {
    font-size: 3.2rem;
    line-height: 3.5rem;
  }

  .subheading {
    font-size: 1.5rem;
  }
}

.sec--reverse {
  text-align: right;
  flex-direction: row-reverse;
}

@keyframes rotate {
  from {
    rotate: none;
  }

  50% {
    scale: 1 1.1;
  }

  to {
    rotate: 360deg;
  }
}

#blob {
  aspect-ratio: 1;
  z-index: -2;
  filter: blur(250px);
  background: linear-gradient(to right, #00eaff, #3800ef);
  border-radius: 50%;
  width: 50vh;
  max-width: 55vw;
  animation: 20s infinite rotate;
  position: absolute;
  top: 50%;
  left: 30%;
}
/*# sourceMappingURL=index.1d14cd81.css.map */
